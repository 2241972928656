<template>
  <div id="__default" class="flex">
    <client-only>
      <Nav :class="{ 'dark-theme': dark }" />
    </client-only>
    <div class="nav md:hidden z-30 w-full" :class="position">
      <div class="container print:!px-0">
        <div class="pt-4 pb-3 border-b border-white print:!border-0" :class="{ 'dark-theme': dark }">
          <div class="flex justify-between w-full print:justify-end">
            <NuxtLink to="/">
              <svg width="113" height="15" viewBox="0 0 113 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M72.4078 6.67842H71.263V6.05833H72.4078V4.62735C72.4078 3.00556 73.3141 2.24237 74.6496 2.24237C74.9358 2.24237 75.222 2.29007 75.5082 2.33777V3.05326C75.2697 2.95786 74.9835 2.91016 74.6973 2.95786C73.8387 2.95786 73.2663 3.48256 73.2663 4.57965V6.01063L75.4605 6.01063V6.72612L73.2663 6.72612V13.881H72.4555L72.4078 6.67842Z"
                  fill="white"
                />
                <path d="M76.7961 6.05833H77.607V13.881H76.8438L76.7961 6.05833Z" fill="white" />
                <path
                  d="M80.0874 12.0208V6.67842L78.9426 6.67842V6.05833H80.0874V4.29345H80.8983V6.05833H82.9493V6.77382H80.8983V12.0208C80.8983 12.9271 81.3753 13.4518 82.0431 13.4518C82.377 13.4518 82.7108 13.3564 83.0447 13.261V13.9287C82.7108 14.0241 82.3293 14.1195 81.9954 14.1195C80.8029 13.9764 80.0874 13.261 80.0874 12.0208Z"
                  fill="white"
                />
                <path
                  d="M85.3343 6.05833V7.58472C85.859 6.53533 86.9561 5.91523 88.1009 5.96293C89.7704 5.96293 90.8675 6.86922 90.8675 9.0634V13.881H90.0566V9.0157C90.0566 7.34622 89.2934 6.63073 87.9101 6.63073C86.5745 6.58303 85.4297 7.58472 85.2866 8.968V13.881H84.4757V6.05833H85.3343Z"
                  fill="white"
                />
                <path
                  d="M92.4415 10.0651V9.96969C92.4415 7.58471 93.9679 5.96293 96.0667 5.96293C97.927 5.96293 99.5011 7.20312 99.5011 9.77889V10.0651H93.2524C93.3478 12.1639 94.3018 13.3564 96.1621 13.3564C97.4023 13.3564 98.3563 12.8317 98.5948 11.7346L99.4057 11.7346C99.0718 13.261 97.8316 14.0718 96.1144 14.0718C93.8725 14.0241 92.4415 12.4024 92.4415 10.0651ZM98.6425 9.3496C98.5471 7.44162 97.5454 6.58303 96.019 6.58303C94.4926 6.58303 93.3955 7.68011 93.2524 9.3496H98.6425Z"
                  fill="white"
                />
                <path
                  d="M100.407 11.5438H101.171C101.266 12.5932 101.981 13.3087 103.46 13.3087C104.796 13.3087 105.416 12.7363 105.416 11.7346C105.416 10.7329 104.843 10.399 103.365 10.1605C101.457 9.82659 100.694 9.3496 100.694 7.96631C100.694 6.58302 101.934 5.86753 103.269 5.86753C104.7 5.86753 105.75 6.43993 106.036 8.01401H105.273C105.082 7.01232 104.462 6.58303 103.317 6.58303C102.172 6.58303 101.552 7.15542 101.552 8.01401C101.552 8.8249 102.077 9.2542 103.699 9.54039C105.32 9.82659 106.322 10.2559 106.322 11.7346C106.322 13.0702 105.32 14.0241 103.556 14.0241C101.648 13.9764 100.55 13.0702 100.407 11.5438Z"
                  fill="white"
                />
                <path
                  d="M107.085 11.5438H107.848C107.944 12.5932 108.659 13.3087 110.138 13.3087C111.474 13.3087 112.094 12.7363 112.094 11.7346C112.094 10.7329 111.521 10.399 110.043 10.1605C108.135 9.82659 107.371 9.3496 107.371 7.96631C107.371 6.58302 108.612 5.86753 109.947 5.86753C111.378 5.86753 112.428 6.43993 112.714 8.01401H111.951C111.76 7.01232 111.14 6.58303 109.995 6.58303C108.85 6.58303 108.23 7.15542 108.23 8.01401C108.23 8.8249 108.755 9.2542 110.377 9.54039C111.998 9.82659 113 10.2559 113 11.7346C113 13.0702 111.998 14.0241 110.233 14.0241C108.325 13.9764 107.228 13.0702 107.085 11.5438Z"
                  fill="white"
                />
                <path
                  d="M77.2731 4.38885C77.5892 4.38885 77.8455 4.13258 77.8455 3.81645C77.8455 3.50033 77.5892 3.24406 77.2731 3.24406C76.957 3.24406 76.7007 3.50033 76.7007 3.81645C76.7007 4.13258 76.957 4.38885 77.2731 4.38885Z"
                  fill="white"
                />
                <path
                  d="M68.258 7.01183C68.258 3.10047 65.2052 0 61.2939 0C57.2394 0 54.2343 3.19587 54.2343 7.20262C54.2343 11.2094 57.3348 14.3098 61.3415 14.3098C64.2035 14.3098 66.9701 12.3542 67.8287 9.6353H64.442C63.7742 10.8278 62.6294 11.3525 61.3415 11.3525C60.006 11.3525 58.8135 10.6847 58.0503 9.5399C57.8595 9.2537 57.6687 8.9198 57.621 8.58591L68.0672 8.58591C68.2103 8.10891 68.258 7.53652 68.258 7.01183ZM57.5256 6.01014C58.0026 4.10216 59.3859 2.90967 61.3415 2.90967C63.2972 2.90967 64.6805 4.05446 64.9667 6.01014L57.5256 6.01014Z"
                  fill="white"
                />
                <path
                  d="M55.3314 0C53.7096 0 53.0895 0.333896 52.1832 1.66948H52.1356V0.429296L49.1782 0.429296V13.8806L52.4217 13.8806V6.34403C52.4217 4.38835 53.2326 3.19587 55.3314 3.10047V0Z"
                  fill="white"
                />
                <path
                  d="M42.071 0C38.1119 0 34.916 3.24357 34.916 7.20262C34.916 11.114 38.1596 14.3575 42.071 14.3575C46.1254 14.3575 49.1782 11.2094 49.1782 7.15492C49.1782 3.19587 46.03 0 42.071 0ZM42.071 11.4002C39.7337 11.4002 38.1119 9.4445 38.1119 7.20262C38.1119 5.00845 39.7814 2.95737 42.071 2.95737C44.3605 2.95737 45.9823 5.05615 45.9823 7.20262C45.9823 9.3968 44.3605 11.4002 42.071 11.4002Z"
                  fill="white"
                />
                <path
                  d="M28.572 11.4002C26.187 11.4002 24.5175 9.4922 24.5175 7.20262C24.5175 4.91305 26.0439 3.00507 28.4289 3.00507C29.9076 3.00507 30.8616 3.72056 31.5771 4.96075H35.2022C34.2005 2.00338 31.5771 0.0477006 28.4289 0.0477006C24.5175 0.0477006 21.3217 3.24357 21.3217 7.15492C21.3217 11.114 24.5175 14.3575 28.4766 14.3575C31.6725 14.3575 34.1528 12.4973 35.2022 9.5399H31.5771C30.8616 10.5893 29.8599 11.4002 28.572 11.4002Z"
                  fill="white"
                />
                <path
                  d="M20.0815 1.86028C19.1275 0.715493 17.5057 0 16.027 0C14.5006 0 13.4036 0.572395 12.3542 1.62178V0.429296L6.29633 0.429296C8.9198 1.43099 10.7324 3.95906 10.7324 6.96413C10.7324 7.48882 10.637 8.06121 10.5416 8.58591L0.0953975 8.58591C0.190797 8.9675 0.333896 9.2537 0.524694 9.5399C1.24019 10.637 2.48037 11.3525 3.81596 11.3525C5.10385 11.3525 6.24863 10.8278 6.91643 9.6353H10.3031C9.683 11.591 8.06121 13.1651 6.15323 13.8806H12.545V7.48882C12.545 6.48713 12.545 4.96075 13.1174 4.10216C13.5944 3.38666 14.4529 2.90967 15.3115 2.90967C17.6965 2.90967 18.0304 5.34234 18.0304 7.20262L18.0304 13.8329H21.274L21.274 6.43943C21.274 4.86535 21.0832 3.10047 20.0815 1.86028Z"
                  fill="white"
                />
                <path
                  d="M3.81596 2.95737C1.86028 2.95737 0.476995 4.10216 0 6.05784L7.44112 6.05784C7.15492 4.10216 5.77164 2.95737 3.81596 2.95737Z"
                  fill="white"
                />
              </svg>
            </NuxtLink>
            <!-- <AtomButtonDropdown
              :icon="dark ? '#282929' : '#fff'"
              class="p-2 m-[-8px] print:!hidden"
              hide-sup
              :items="cabinetItems"
              @select="onCabinetSelect"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="hidden bg-gray-100 md:flex menu fixed w-full z-40 pl-[75px] min-h-full flex-col justify-between overflow-auto max-h-full"
      :class="{ open: showMenu }"
    >
      <FooterNav />
      <FooterForms />
      <FooterMenuLastNav />
    </div>
    <div class="hidden md:block min-w-[75px] w-[75px] bg-gray-100 absolute z-[60]">
      <div
        class="fixed left-0 min-w-[75px] w-[75px] min-h-[100svh] max-h-full top-0 bottom-0 flex flex-col justify-between pb-7 items-center"
      >
        <div
          class="pt-5 w-full h-full inline-flex justify-center cursor-pointer burger-wrap"
          @click="showMenu = !showMenu"
        >
          <button class="burger" :class="{ open: showMenu }">
            <span />
            <span />
            <span />
            <span />
          </button>
        </div>
        <NuxtLink to="/">
          <div class="logo-css"></div>
        </NuxtLink>
      </div>
    </div>
    <SmoothScroll :class="{ 'opacity-0 pointer-events-none max-h-screen overflow-hidden': hasPreloader }">
      <Nuxt />
      <client-only>
        <Footer />
      </client-only>
    </SmoothScroll>
    <Preloader @after-logo-hide="beforePreloaderUnmount" />
    <client-only>
      <div class="relative" style="z-index: 70">
        <portal-target name="backdrop"></portal-target>
      </div>
      <div class="relative z-50">
        <portal-target name="backdrop-modal"></portal-target>
      </div>
      <div class="relative" style="z-index: 70">
        <portal-target name="button_dropdown"></portal-target>
      </div>
      <div class="relative" style="z-index: 70">
        <portal-target name="modal-simple-video"></portal-target>
      </div>
      <div class="relative z-50">
        <portal-target name="modal"></portal-target>
      </div>
      <div class="relative z-50">
        <portal-target name="modal-nested"></portal-target>
      </div>
    </client-only>
    <div ref="amopixel"></div>
  </div>
</template>

<script>
// import AtomButtonDropdown from '~/components/atoms/AtomButtonDropdown'
import Footer from '~/components/builder/core/Footer'
import SmoothScroll from '~/components/SmoothScroll'
import FooterForms from '~/components/templates/layouts/FooterForms'
import FooterMenuLastNav from '~/components/templates/layouts/FooterMenuLastNav'
import FooterNav from '~/components/templates/layouts/FooterNav'
import Nav from '~/components/templates/layouts/Nav'
import Preloader from '~/components/templates/layouts/Preloader'
import { appLdJson, canonical, facebook, seo, title, twitter } from '~/utils/getHeadData'

export default {
  components: {
    // AtomButtonDropdown,
    Nav,
    SmoothScroll,
    FooterNav,
    FooterForms,
    FooterMenuLastNav,
    Preloader,
    Footer,
  },
  data: () => {
    return {
      cityId: null,
      hasPreloader: true,
      showMenu: false,
      scroll: null,
    }
  },
  head() {
    const { path } = this.$route
    const siteUrl = this.$config.siteUrl

    return {
      title: title(path, this.metaPage, this.metaDefault),
      meta: [
        ...seo(path, this.metaPage, this.metaDefault),
        ...facebook(path, this.metaPage, this.metaDefault, siteUrl),
        ...twitter(path, this.metaPage, this.metaDefault, siteUrl),
      ].filter(item => !!item.content),

      link: [...canonical(path, siteUrl)],

      script: [...appLdJson(path, siteUrl)],
    }
  },
  computed: {
    cabinetItems() {
      return this.$store.getters['modules/content/getClubsAsListCabinetMenu']
    },
    metaDefault() {
      const global = this.$store.getters['modules/content/global']
      return global?.meta || {}
    },
    metaPage() {
      const page = this.$store.getters['modules/content/page']
      return page?.meta || {}
    },
    position() {
      return this.$route.name === 'home' ? 'fixed' : 'absolute'
    },
    dark() {
      try {
        const { options } = this.$route.matched[0].components.default
        return options.meta?.theme === 'dark'
      } catch (e) {
        return this.$route.meta.theme === 'dark'
      }
    },
    scrollIsBlocked() {
      return this.$store.getters['scrollBlocker/isBlocked']
    },
    pageTransitionInAction() {
      return !this.$store.getters['pageTransition/canNavigate']
    },
  },
  watch: {
    $route(to, from) {
      // do not scroll on nested routes
      const betweenChildToChild =
        to.matched.length > 1 && from.matched.length > 1 && to.matched[0].path === from.matched[0].path
      const onlyQueryChanged = to.path === from.path // && !objectEquals(to.query, from.query)
      if (!betweenChildToChild && !onlyQueryChanged) {
        if (this.scrollIsBlocked) {
          this.shouldScrollToTop = true
        } else {
          this.scrollTop()
        }
      }
      this.showMenu = false
      this.amocrmPixel()
    },
    scrollIsBlocked() {
      if (!this.scrollIsBlocked && this.shouldScrollToTop && this.pageTransitionInAction) {
        this.scrollTop()
      }
    },
    pageTransitionInAction() {
      if (!this.pageTransitionInAction) {
        this.$root.$loco.update()
      }
    },
  },
  beforeMount() {
    this.locomotiveScrollInit()
  },
  mounted() {
    this.$store.dispatch('resourceLoader/watchResourcesLoad')
    this.amocrmPixel()
  },
  methods: {
    onCabinetSelect(i) {
      const cabinet = this.cabinetItems[i]
      window.open(cabinet.href)
    },
    scrollTop() {
      this.shouldScrollToTop = false
      this.$root.$loco.setScroll(0, 0)
      this.$root.$loco.update()
      window.scrollTo(0, 0)
    },
    beforePreloaderUnmount() {
      this.hasPreloader = false
      this.$nextTick(() => {
        this.$store.dispatch('entryLoaderScreen/hidePreloadScreen')
        this.$root.$loco.update()
        this.locomotiveScrollAutoUpdate()
      })
    },
    locomotiveScrollInit() {
      this.$root.$loco = new this.$LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        getDirection: true,
        tablet: {
          smooth: false,
          breakpoint: 960,
        },
        smartphone: {
          smooth: false,
          breakpoint: 960,
        },
      })
      this.$store.dispatch('scrollBlocker/setInstance', this.$root.$loco)
    },
    locomotiveScrollAutoUpdate() {
      let height = getHeight()
      this.interval = setInterval(() => {
        if (this.scrollIsBlocked) {
          return
        }
        if (height !== (height = getHeight())) {
          this.$root.$loco.update()
          // ближайший update, после разблокировки скролла,
          // не применяется, видимо внутри loco где-то таймаут
          setTimeout(() => {
            this.$root.$loco.update()
          }, 200)
        }
      }, 100)
      function getHeight() {
        return document.querySelector('#__default').clientHeight
      }
    },
    amocrmPixel() {
      const inlineScript = document.createElement('script')
      const inlineScriptContent = document.createTextNode(
        `(function (w, d) {w.amo_pixel_token = 'p1Ip4iB8yqaEyXU9HPmcTHcfjCvrx5zKkhfnVDEMGItlN6QOkatXHAkZOrbTucdzzon+B15p1Vr3+rgFLYIDSQ==';var s = document.createElement('script'), f = d.getElementsByTagName('script')[0];s.id = 'amo_pixel_js';s.type = 'text/javascript';s.async = true;s.src = 'https://piper.amocrm.ru/pixel/js/tracker/pixel.js?token=' + w.amo_pixel_token;f.parentNode.insertBefore(s, f);})(window, document);`,
      )
      inlineScript.appendChild(inlineScriptContent)

      this.$refs.amopixel.replaceChildren(inlineScript)
    },
  },
}

// function objectEquals(obj1, obj2) {
//   return (
//     Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => obj1[key] === obj2[key])
//   )
// }
</script>

<style lang="postcss" scoped>
.dark-theme {
  @apply !border-gray-100;
  svg path {
    @apply fill-gray-100;
  }
}
.menu {
  transform: translateX(-100%);
  transition: transform 0.4s var(--cubic-bezier);
  &:before {
    content: '';
    position: absolute;
    left: 75px;
    height: 100%;
    width: 1px;
    background: #47474a;
  }
  &.open {
    transform: translateX(0%);
    z-index: 55;
  }
  .footer-nav-last::after {
    @apply hidden;
  }
}
.burger-wrap {
  &:hover {
    .burger {
      span {
        &:nth-child(1) {
          top: 4px;
        }
        &:nth-child(4) {
          top: 28px;
        }
      }
      &.open {
        span {
          &:nth-child(2),
          &:nth-child(3) {
            left: 4px;
            right: 4px;
          }
        }
      }
    }
  }
}
.burger {
  width: 45px;
  height: 33px;
  padding: 8px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 1px;
    background: #fff;
    opacity: 1;
    left: 8px;
    right: 8px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 8px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 16px;
    }
    &:nth-child(4) {
      top: 24px;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
</style>
